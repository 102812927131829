import React from "react";
import styled from "styled-components";
//
import Footer from "./footer";
import Header from "./header";
//
export default ({ videos, main_image, ...rest }) => (
  <Wrap>
    <Header {...rest} />
    <img src={main_image.url} alt={main_image.alt} />
    <Footer {...rest} />
    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.div`
  margin: 0 auto;
  padding: 0;

  img {
    max-height: 30vh;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  @media (min-width: 768px) {
    img {
      max-height: 50vh;
    }
  }
`;

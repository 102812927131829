import React from "react";
import styled from "styled-components";
//
import Mail from "components/Envelope";
import Instagram from "components/Instagram";
import Youtube from "components/Youtube";
//
export default ({ ...rest }) => (
  <Wrap>
    <Contact>
      {/* <h2>Contact</h2> */}

      <Link>
        <a href="mailto:tuulia.ritsila@gmail.com">
          <Mail />
        </a>
      </Link>
      {/* <Link>
        <a href="https://www.instagram.com/salatuulia" target="_blank">
          <Instagram />
        </a>
      </Link>
      <Link>
        <a
          href="https://www.youtube.com/channel/UCzVh4mrAegg-Qib3WiJPzgw/videos"
          target="_blank"
        >
          <Youtube />
        </a>
      </Link> */}
    </Contact>
  </Wrap>
);
//
const Wrap = styled.div`
  bottom: 0;
  position: fixed;
  margin: 0 auto;
  padding: 0.5rem;
  width: 100%;

  @media (min-width: 768px) {
    padding: 1rem;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
`;

const Link = styled.div`
  height: auto;
  width: 30px;
  margin: 0 5px 0 5px ;
  svg {
    width: 100%;
  
    :hover {
      fill: #e40066;
    }
`;

import React from "react";
import styled from "styled-components";
//
import Link from "./link";
//
import { RichText } from "components/prismic";
//
export default ({ description, links, title, top_links, ...rest }) => (
  <Wrap>
    <List>
      <Title>{title}</Title>
      <Links>
        {top_links.map((link, i) => (
          <Link key={i} {...link} />
        ))}
      </Links>
    </List>
    <List>
      <h2>Projektit</h2>
      <Links>
        {links.map((link, i) => (
          <Link key={i} {...link} />
        ))}
      </Links>
    </List>
    <RichText {...description} />
  </Wrap>
);
//
const Wrap = styled.div`
  padding: 0.5rem;
  @media (min-width: 768px) {
    padding: 0.5rem 1rem;
  }
`;
const Title = styled.h1``;
const List = styled.div`
  display: flex;
  padding: 0.1rem 0;
`;
const Links = styled.div`
  z-index: 101;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

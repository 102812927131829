import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//

export default ({ cover, title, path, ...rest }) => (
  <Wrap>
    <Link to={path} title={title}>
      <h3>{title}</h3>
    </Link>
    <img src={cover.url} alt={cover.alt} />

    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.div`
  position: relative;
  padding: 0.15rem 0.3rem 0.1rem 0.3rem;
  img {
    display: none;
  }
  @media (min-width: 768px) {
    padding: 0.15rem 0.3rem;

    img {
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 101;
    }

    :hover {
      img {
        display: block;
        z-index: 101;
      }
    }
  }
`;
